import { axios } from "./axiosInterceptor";

const base_url = "https://vas.worlinks.com/api/gamerClub/mobily-ksa";

// get decrypt api

export let getPhone = async (msisdn) => {
  let { data } = await axios.post(`${base_url}/decrypt?msisdn=${msisdn}`);

  return data;
};

// get Header Enrichment api
export let getHeaderEnrichment = async () => {
  let { data } = await axios.get(
    `${base_url}/he`
  );
  return data;
};

// get subscribe api

export let subscribeRequest = async (phone) => {
  let formData = new FormData();
  formData.append('phone', phone);
  formData.append('btn_id', 'btn-sub');

  let { data } = await axios.post(`${base_url}/subscribe`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return data;
};


// get verify  from api
export let verifyPin = async (params) => {
  let { data } = await axios.get(`${base_url}/verify`, {
    params,
  });
  return data;
};


