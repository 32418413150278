import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "./../../../assets/imgs/check.png";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { gtagReportConversion } from "./../../../Utilies/Utilies/helper";
import Layout from "./../../../Components/layout";

export default function KsaSuccess() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  // Convert searchParams to an object
  const params = Object.fromEntries(searchParams.entries());
  let trafficsource;
  let parameter;

  useEffect(() => {
    getMarktingParamsFromLocalStorage();
   
  });
 

  const getMarktingParamsFromLocalStorage = () => {
    trafficsource = localStorage.getItem("trafficsource");
    parameter = localStorage.getItem("parameter");
  };

  return (
    <>
      <Layout>
        <img className="servImg mb-5" src={logo} alt="" />

        <h4>{t("thankYouText")}</h4>
      </Layout>
    </>
  );
}
