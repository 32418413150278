import React from 'react'

const ErrorMssg = ({errMsg}) => {
  return (
      <p className="w-100 overflow-hidden bg-danger-subtle rounded text-danger p-2">
          {errMsg}
      </p>
  )
}

export default ErrorMssg