import { axios } from "../api/axiosInterceptor";

const base_url = "https://vas.worlinks.com/api/gamerClub";

export let signinFormWorLinks = async (formData) => {
  let { data } = await axios.post(`${base_url}/api/login`, formData);
  return data;
};
export let signinFormKorek = async (formData) => {
  let { data } = await axios.post(`${base_url}/koreq-iraq/login`, formData);
  return data;
};
export let signinFormKsa = async (formData) => {
  let { data } = await axios.post(`${base_url}/mobily-ksa/login`, formData);
  return data;
};
export let signinFormZain= async (formData) => {
  let { data } = await axios.post(`${base_url}/zain-iq-d/login`, formData);
  return data;
};
