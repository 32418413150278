import React from 'react'
import { useTranslation } from 'react-i18next';

const SubscribeBtn = ({btn}) => {
    const { t } = useTranslation();

  return (
    <button type="submit" id="btn-sub" className="btn btn-sub">
      <img className="btn-bg" src={btn} alt="" />
      <h3 className="btn-txt ">{t("subscribe")}</h3>
    </button>
  );
}

export default SubscribeBtn