import { axios } from "./axiosInterceptor";

const base_url = "https://vas.worlinks.com/api/gamerClub/koreq-iraq";

// get grtAntifraud api
export let getAntifraud = async (type, btn) => {
  let { data } = await axios.get(
    // `${base_url}/antifraud` , {type,btn}
    `${base_url}/antifraud?type=${type}&btn=${btn}`
  );
  return data;
};
// get Header Enrichment api
export let getHeaderEnrichment = async (time, transaction) => {
  let { data } = await axios.get(
    // `${base_url}/antifraud` , {type,btn}
    `${base_url}/checkout?time=${time}&transaction=${transaction}`
  );
  return data;
};

// get subscribe api
export let subscribeRequest = async (phone) => {
  let { data } = await axios.get(`${base_url}/subscribe?phone=${phone}`);
  return data;
};

// get verify  from api
export let verifyPin = async (params) => {
  let { data } = await axios.get(`${base_url}/verify`, {
    params,
  });
  return data;
};

export let checkout = async (params) => {
  let { data } = await axios.get(`${base_url}/check`, {
    params,
  });
  return data;
};
