import React, { useState } from "react";
import "../signIn.css";
import side_img from "../../../assets/forms/side_signin_img.png";
import google_img from "../../../assets/forms/google_verify.png";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../../Components/FormsAside/FormsAside";
import { otpCode } from "../../../Utilies/api";
import { notify } from "../../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { signinFormKsa } from "../../../Utilies/worLinksApi";

const KsaSignin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // lodaing state
  const [lodaing, setLodaing] = useState(true);
  // errors messages state
  const [errorMsg, setErrorMsg] = useState("");
  // user state
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const signUpKey = "signup-ar";
    const signinKey = "signin-ar";
    const DHAKey = "dont have account?-ar";
    const phoneNumberKey = "Phone-Number-ar";
  
    // send data to api
    async function sendData() {
      try {
        const formData = {
          phone,
          password,
        };
        setLodaing(false);
        
          const result = await signinFormKsa(formData);
  
          if (result.token) {
            localStorage.setItem("user_id", result?.user?.id);
            // store token to local storage
            localStorage.setItem("token", result?.token);
            localStorage.setItem(
              "userImage",
              JSON.stringify(result?.user?.avatar)
            );
            setTimeout(() => {
              navigate("/success", { replace: true });
            }, 2500);
          }
        
        notify("SignIn Successfully");
        localStorage.setItem("userPhone", formData.phone);
        setLodaing(false);
        setErrorMsg("");
        setTimeout(() => {
          // navigate("/otp");
        }, 1500);
      } catch (error) {
        setErrorMsg(error.response.data.message);
        setLodaing(true);
      }
    }

  return (
    <div className="signUp">
      <ToastContainer />
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <FormsAside side_img={side_img} />
        <Col xs={12} md={7} lg={8} xl={8} className="form">
          <Row className="h-10" style={{ paddingTop: "10%" }}>
            <Col className="m-auto" xs={12} md={8} lg={6}>
              <h1>{t(signinKey)}</h1>
              {/* <div className="google">
                <img src={google_img} alt="google" className="mx-2" />
                {t("Sign In With Google")}
              </div>
              <span>{t("-OR-")}</span> */}
              {errorMsg ? (
                <div className="alert alert-danger">{errorMsg}</div>
              ) : (
                ""
              )}
              <form className="signUp-form">
                <input
                  className="input px-2"
                  dir={
                    localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
                  }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  name="phone"
                  placeholder={t(phoneNumberKey)}
                  required
                />
                <input
                  className="input mt-2 mb-2 px-2"
                  dir={
                    localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  required
                />
              </form>
              <div className="submit-info">
                <button className="btn-submit" onClick={() => sendData()}>
                  {lodaing ? (
                    t(signinKey)
                  ) : (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                </button>
                <h3>
                  {t(DHAKey)} <Link to="/signup">{t(signUpKey)}</Link>
                </h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default KsaSignin;
