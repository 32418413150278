import React, { useEffect } from "react";
import logo from "./../../../assets/imgs/x-button.png";

import { useTranslation } from "react-i18next";
import Layout from "../../../Components/layout";
import { useNavigate } from "react-router-dom";

function KsaFail() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // what is the point
  useEffect(() => {}, [navigate]);

  return (
    <>
      <Layout>
        <img className="servImg mb-4" src={logo} alt="" />

        <h4>{t("failText")}</h4>
        <h5>{t("Sendsub")}</h5>

        <p>{t("feesText")}</p>
      </Layout>
    </>
  );
}

export default KsaFail;
