import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import btn from "./../../../assets/imgs/Assetbtn.svg";
import logo from "./../../../assets/imgs/logo2.32a2f0e40fc629cb6e51.png";

import {
  getPhone,
  getHeaderEnrichment,
  subscribeRequest,
} from "./../../../Utilies/Utilies/ksa-api";
import { useTranslation } from "react-i18next";

import Layout from "./../../../Components/layout";
import { handlePhoneNumber } from "./../../../Utilies/Utilies/helper";
import ErrorMssg from "./../../../Components/ErrorMssg/ErrorMssg";
import PhoneInputKsa from "./../../../Components/PhoneInputKsa/PhoneInputKsa";
import SubscribeBtn from "./../../../Components/SubscribeBtn/SubscribeBtn";
import { queries } from "@testing-library/react";

function KsaSignup() {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  let [isPin, setIsPin] = useState(false);
  const [searchParams] = useSearchParams();
  let time, transaction;
  // Convert searchParams to an object
  const params = Object.fromEntries(searchParams.entries());

  const [phone, setPhone] = useState("");
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);
  useEffect(() => {
    setMarktingParamsToLocalStorage();
  }, [params]);
  const location = useLocation();
  const pin = "";
  const setScript = async () => {
    try {
      
         const msisdn = searchParams.get("msisdn"); 

     const phone = await getPhone(msisdn);
      
     
      
    } catch (error) {
      console.error("Error fetching antifraud script:", error);
    }
  }; 
 
  const handleSubscription = async (phone) => {
    if (!isPin) {
      try {
        const response = await getHeaderEnrichment();
        if (response.success === true) {
          const { url } = response;
          
          if (url) {
            window.location.href = url;
          }
        }
      } catch (error) {
        setErrMsg(error.response.data.message);
      }
    } else {
      try {
        let phoneNumber = handlePhoneNumber(phone, 966);
        const response = await subscribeRequest(phoneNumber, "btn-sub");
        console.log("subscribe Response:", response);
        if (response.success === true) {
          localStorage.setItem("time",response.data.ti);
          localStorage.setItem("transaction",response.data.ts)

          navigate("/ksa-verify", { state: phoneNumber });
        } else {
          setErrMsg(response.message);
        }

        // if (response.status === 200) {
        //   navigate("/pinCode");
        // } else {
        //   navigate("/fail");
        // }
      } catch (error) {
        setErrMsg(error.response.data.message);
      }
    }
  };
  const setMarktingParamsToLocalStorage = () => {
    const trafficsource = params?.trafficsource;
    const parameter = params?.parameter;
    if (trafficsource) {
      localStorage.setItem("trafficsource", trafficsource);
      localStorage.setItem("parameter", parameter);
    }
  };
  useEffect(() => {
    if (location.pathname === "/proceeding") {
      setIsPin(true);
     setScript("pin");
    } else {
     setScript("he");
    }
  }, [pin]);

  return (
    <>
      <Layout>
        <img className="servImg" src={logo} alt="" />

        <h4>{t("SubscribeNowText")}</h4>

        <form
          className="mb-3"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            handleSubscription(phone); // Call the subscribe function with the entered phone number
          }}
        >
          {isPin && (
            <>
              <label htmlFor="phoneInput" className="form-label">
                {t("Enteryourmobilenumber")}
              </label>
              <PhoneInputKsa setPhone={setPhone} />
              {errMsg ? <ErrorMssg errMsg={errMsg} /> : ""}
            </>
          )}
          <SubscribeBtn btn={btn} />
        </form>

        <p>{t("feesTextKsa")}</p>
      </Layout>
    </>
  );
}

export default KsaSignup;
