import React from 'react'

const PhoneInputKsa = ({setPhone}) => {
    return (
      <div className="input-group mb-3">
        <span className="input-group-text cursor-default" id="basic-addon1">
          +966
        </span>
        <input
          type="text"
          className="form-control"
          id="phoneInput"
          placeholder="XXX-XXX-XX"
          aria-describedby="basic-addon1"
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
    );
}

export default PhoneInputKsa